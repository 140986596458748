<template>
	<div class="main-content">
		<ktv-breadcrumb title="Sub Category" page="Sub Category" folder="Training" />
		<ktv-table
			:columns="columns"
			:is-loading="tableLoad"
			:line-numbers="true"
			:rows="mainData.lists"
			:filter="false"
			:total-rows="mainData.total"
			:mode="modeTable"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button text="Add Sub Category" icon="add" color="green-light" @click="openForm('add')" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span class="btndropdownaction">
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openForm(props.row)">
							<img :src="iconedit" class="imgicon"> Update
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="deleteSubCategory(props.row)">
							<img :src="icondelete" class="imgicon"> Delete
						</b-dropdown-item>
					</b-dropdown>
				</span>

				<!-- <span v-if="props.column.field == 'button'">
					<a v-b-tooltip.hover href="#" title="View Detail" @click="openForm(props.row)">
						<i class="i-Eye text-18 text-success mr-2" />
						{{ props.row.button }}</a>
					<a v-b-tooltip.hover href="#" title="Delete" @click="deleteSubCategory(props.row)">
						<i class="i-Close-Window text-18 text-danger" />
						{{ props.row.button }}</a>
				</span> -->
				<span v-else-if="props.column.field == 'language_data'">
					{{ getSubCategoryName(props.row.language_data) }}
				</span>
				<span v-else-if="props.column.field == 'SubCategoryParentCategoryId'">
					{{ getCategoryName(props.row.SubCategoryParentCategoryId) }}
				</span>
			</template>
		</ktv-table>
		<ktv-modal v-model="formModal" size="lg" :title="titleModals" :no-close-on-backdrop="true" :no-close-on-esc="true" @close="closeForm()">
			<template #content>
				<b-form-group class="col-md-12 mb-3" label="Main Category" label-for="input-1">
					<b-form-select
						v-model="category"
						v-validate="'required'"
						:options="categories"
						name="main category"
						:state="validateState('main category')"
					/>
					<b-form-invalid-feedback>
						{{ veeErrors.first("main category") }}
					</b-form-invalid-feedback>
				</b-form-group>
				<label class="pl-3">how many add sub category</label>
				<div class="row mb-2 px-3">
					<b-col cols="12">
						<b-form-input v-model="countSubCategory" class="float-left col-md-1" type="number" />
						<b-btn variant="success" class="float-right" @click="addSubCategory(countSubCategory)"> Add Sub Category </b-btn>
					</b-col>
				</div>
				<div v-for="(v, i) in subCategoryData" :key="i" class="row px-3">
					<b-col cols="12">
						<label>Sub Category {{ i + 1 }} <b-icon icon="trash" class="cursor-pointer ml-1" @click="removeSubCategory(i)" /></label>
						<b-row>
							<b-form-group v-for="(v2, i2) in languages" :key="i2" class="col-md-4" :label="v2.language_desc">
								<b-form-input
									v-model="subCategoryData[i].language[i2].languageText"
									v-validate="v2.language_desc === 'Indonesia' ? 'required' : ''"
									size="sm"
									placeholder="..."
									:name="v2.language_desc"
									:state="validateState(v2.language_desc)"
								/>
								<b-form-invalid-feedback>
									{{ veeErrors.first(v2.language_desc) }}
								</b-form-invalid-feedback>
							</b-form-group>
						</b-row>
					</b-col>
				</div>
			</template>
			<template #footer>
				<b-button v-show="action === 'add'" variant="primary" size="sm" class="float-right" @click="saveData(subCategoryData)"> Save </b-button>
				<b-button variant="light" size="sm" class="float-right" style="margin-right: 5px" @click="closeForm()"> Cancel </b-button>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvBreadcrumb, KtvModal, KtvTable, KtvButton } from "@/components"

	export default {
		metaInfo: {
			title: "Sub Category",
		},
		components: { KtvBreadcrumb, KtvModal, KtvTable, KtvButton },
		data() {
			return {
				/* eslint-disable global-require */
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				tableLoad: false,
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Category",
						field: "SubCategoryParentCategoryId",
						thClass: "text-left",
					},
					{
						label: "Name",
						field: "language_data",
						thClass: "text-left",
					},
				],
				rows: [],
				modalsform: false,
				titleModals: "Add Sub Category",
				formModal: false,
				subCategoryData: [],
				countSubCategory: 1,
				categories: [],
				languages: [],
				language: "",
				action: "",
				category: "",
				serverParams: {
					rowPerpage: 10,
					currentPage: 1,
					columnFilters: { SubCategoryParentCategoryId: "" },
					sort: [
						{
							field: "SubCategoryParentCategoryId",
							type: "ASC",
						},
					],
				},
				mainData: {
					lists: [],
					total: 0,
				},
				modeTable: "remote",
			}
		},
		watch: {
			languages: {
				immediate: true,
				handler: function (v) {
					this.subCategoryData = [{ language: [] }]
					v.forEach((v2) => {
						this.subCategoryData[0].language.push({
							languageCode: v2.language_code,
							languageText: "",
							languangeDesc: v2.language_desc,
						})
					})
				},
			},
		},
		mounted() {
			this.getGridMainData()
			this.getLanguages()
			this.getCategories()
		},
		methods: {
			getGridMainData() {
				this.tableLoad = true
				this.$http
					.Post(this.serverParams, this.$urlApi.training.subCategory.getList)
					.then((r) => {
						this.tableLoad = false
						if (r.success) {
							this.mainData.lists = r.results.data
							this.mainData.total = r.results.total
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.tableLoad = false
						this.$swal("Error!", e.response.data.message, "error")
					})
			},
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			openForm(val) {
				this.action = val
				this.formModal = true
				this.category = val?.SubCategoryParentCategoryId
				if (this.category) {
					this.subCategoryData = []
					let data = []
					this.languages.forEach((v) => {
						data.push({
							languageCode: v.language_code,
							languageText: val.language_data.find((item) => item.SubCategoryLanguageCode === v.language_code)?.SubCategoryLanguageText,
							languangeDesc: v.language_desc,
						})
					})
					this.subCategoryData.push({ language: data })
				} else {
					this.addSubCategory(1)
				}
			},
			closeForm() {
				this.formModal = false
				this.subCategoryData = []
			},
			addSubCategory(count) {
				let obj = {
					language: [],
				}
				this.languages.forEach((item) => {
					obj.language.push({
						languageCode: item.language_code,
						languageText: "",
						languangeDesc: item.language_desc,
					})
				})
				for (let i = 0; i < count; i += 1) {
					this.subCategoryData.push(obj)
				}
			},
			getCategories() {
				this.categories = []
				this.showLoadingMessage("Loading Data...")
				this.$http
					.Get({}, this.$urlApi.training.category.get)
					.then((r) => {
						this.$swal.closeModal()
						if (r.success) {
							r.results.forEach((v) => {
								this.categories.push({
									text: v.CategoryLanguageText,
									value: v.CategoryId,
								})
							})
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Error!", e.response.data.message, "error")
					})
			},
			removeSubCategory(index) {
				if (index > -1) {
					this.subCategoryData.splice(index, 1)
				}
			},
			getLanguages() {
				this.showLoadingMessage("Loading languages data...")
				this.$http
					.Get({}, this.$urlApi.training.language)
					.then((r) => {
						this.$swal.closeModal()
						if (r.success) {
							this.languages = r.results
							this.languages.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.$swal.closeModal()
						this.$swal("Error!", e.response.data.message, "error")
					})
			},
			saveData(data) {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}
					if (this.action === "add") {
						this.createData(data)
					}
					//  else {
					// 	this.updateData()
					// }
				})
			},
			createData(data) {
				let newData = []
				data.forEach((item) => {
					let val = item.language.filter((v) => !(v.languageText === ""))
					val.forEach(function (v) {
						delete v.languangeDesc
					})
					newData.push({
						language: val,
					})
				})
				this.showLoadingMessage("Saving Data...")
				let payload = {
					categoryId: this.category,
					subCategoryData: newData,
				}
				this.$http
					.Post(payload, this.$urlApi.training.subCategory.create)
					.then((response) => {
						if (response.success) {
							this.$swal.closeModal()
							this.$swal("Saved!", "Your data has been added.", "success")
							this.getGridMainData()
						}
					})
					.catch((error) => {
						this.$swal.closeModal()
						this.$swal("Failed!", error.response.data.error_message, "error")
					})
			},
			deleteData() {},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.getGridMainData()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.getGridMainData()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1,
				})
				this.getGridMainData()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.getGridMainData()
			},
			onSearchFilter(params) {
				this.updateParams({
					columnFilters: { SubCategoryName: params.searchTerm },
				})
				this.getGridMainData()
			},
			getSubCategoryName(langs) {
				return langs.find((v) => v.language_desc === "Indonesia")?.SubCategoryLanguageText
			},
			getCategoryName(val) {
				return this.categories.find((v) => v.value === val)?.text
			},
			deleteSubCategory(item) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((result) => {
					if (result.value) {
						this.showLoadingMessage("Deleting Data...")
						let payload = {
							subCategoryId: item.SubCategoryId,
						}
						this.$http
							.Delete(payload, this.$urlApi.training.subCategory.delete)
							.then((response) => {
								if (response.success) {
									this.getGridMainData()
									this.$swal.closeModal()
									this.$swal("Deleted!", "Your data has been deleted.", "success")
								} else {
									this.$swal.closeModal()
									this.$swal("Error!", "process error", "error")
								}
							})
							.catch((error) => {
								this.$swal("Failed!", error.response.data.error_message, "error")
								// this.$swal("Error!", "process error", "error");
							})
					}
				})
			},
		},
	}
</script>
